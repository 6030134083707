<template>
  <div class="content-wrapper">

    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Organizations</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Organizations</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

      <!-- Default box -->
      <div class="card" id="organizations">
        <div class="card-header">
          <h3 class="card-title">Detail</h3>
          <div class="card-tools">
            <router-link class="btn btn-info" :to="{name: 'Organizations'}">
              <i class="fas fa-list"></i> List
            </router-link>
          </div>
        </div>

        <div class="overlay" v-if="isLoading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-striped">
            <tbody>
            <tr>
              <td><b>Name</b></td>
              <td>{{ organization.org_name }}</td>
            </tr>
            <tr>
              <td><b>Organization Logo</b></td>
              <td><img v-if="organization.org_logo" v-bind:src="showLogo" class="img-thumbnail">
              </td>
            </tr>
            <tr>
              <td><b>Created At</b></td>
              <td>{{ !_.isEmpty(organization.created_at) ? organization.created_at : '' }}</td>
            </tr>
            <tr>
              <td><b>Updated At</b></td>
              <td>{{ !_.isEmpty(organization.updated_at) ? organization.updated_at : '' }}</td>
            </tr>
            </tbody>
          </table>

        </div>
        <!-- /.card-body -->
        <div class="card-footer">

        </div>
        <!-- /.card-footer-->
      </div>
      <!-- /.card -->


    </section>
    <!-- /.content -->

  </div>
</template>

<script>
import { OrganizationsAPI} from "@/services/api";

export default {
  name: 'OrganizationView',
  // components: {
  //   DatePicker
  // },
  data: () => ({
    params: {},
    organization: {},
    isLoading: false,
    showLogo: ''
  }),
  mounted: function () {

    this.getOrganizationDetail(this.$route.params.id);
    // let _this = this;
    // _this.param = _this.$route.params;
    // _this.getUserDetail(_this.param.id);
    // this.api_url = '/organizations';
    // _this.getOrganizationDetail(_this.param.id);
    // this.getOrganization();
    //console.log('env::', this.API_BASE_URL);
  },
  methods: {
    // getOrganizationDetail: function (id) {
    //   // let token = sessionStorage.getItem("token");
    //   // const AuthStr = 'Bearer '.concat(token);
    //   // let api_url = api_base_url + '/organizations/'+id;
    //   // let _this = this;
    //   // _this.isLoading = true;
    //
    //   let api_url = '/organizations/' + id;
    //   let _this = this;
    //   _this.is_loading = true;
    //
    //   axios.get(api_url)
    //     .then(response => {
    //       //console.log('response.data::', response.data);
    //       _this.organization = response.data;
    //       _this.asset_get_url = process.env.VUE_APP_ASSET_UPLOAD_URL;
    //       _this.showLogo = _this.asset_get_url + '/' + _this.organization.org_logo;
    //       _this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log('error ' + error);
    //     });
    // }

    getOrganizationDetail(id) {
      OrganizationsAPI.show(id).then(organization => {
        this.organization = organization;
        this.asset_get_url = process.env.VUE_APP_ASSET_UPLOAD_URL;
        this.showLogo = this.asset_get_url + '/' + this.organization.org_logo;
        this.isLoading = false;
      });
    }
  }
}
</script>
